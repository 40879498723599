@font-face {
    font-family: "Work Sans";
    src: url("WorkSans-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("WorkSans-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("WorkSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("WorkSans-SemiBold.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("WorkSans-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("WorkSans-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
}