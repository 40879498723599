.profile__section__main {
    padding-top: 172px;

    .profile__img__section{
        img{
            width: 309px;
            margin-top: 20px;
        }
    }

    h3 {
        color: #E66BBA;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 0 !important;
    }

    p {
        color: var(--Neutral-White, #F2F7FC);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        max-width: 872px;
        margin: auto;
        margin-top: 18px;
        margin-bottom: 0 !important;
    }

    .profile__btn__section {
        button {
            opacity: 0.9;
            color: var(--Neutral-White, #F2F7FC);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-decoration-line: underline;
            padding: 0;
            background-color: transparent;
            border: none;
            outline: none;
            margin-top: 18px;
        }
    }
}