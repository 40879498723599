@import "bootstrap/dist/css/bootstrap.min.css";
@import "antd/dist/reset.css";


:root {
    --primary-font: "Sora";
    --worksans:"Work Sans";
    --dark-blue: #0C2445;
    --normal-blue: #0D1C33;
    --primary-color: #FFC2C0;
    --white: #F2F7FC;
    --pink: #E66BBA;
    --light-blue: #0E2D58;
}

* {
    font-family: var(--primary-font);
}


.custom__container {
    width: 100%;
    padding: 0 20px;
    overflow: hidden;

    @media (min-width:576px) {
        max-width: 100%;
        padding: 0 40px;
    }

    @media (min-width:768px) {
        max-width: 100%;
        padding: 0 50px;
    }

    @media (min-width:992px) {
        // max-width: 960px;
        max-width: 100%;
        padding: 0 50px;
    }

    @media (min-width:1200px) {
        max-width: 100%;
        padding: 0 80px;
    }

    @media (min-width:1700px) {
        max-width: 1540px;
        margin: auto;
    }
}