.hero__section__main {
    padding-top: 139px;
    padding-bottom: 130px;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .hero__sub__div {
        width: calc(50% - 20px);

        &.hero__text__section {
            display: flex;
            flex-direction: column;
            gap: 28px;

            h2 {
                color: var(--white);
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 0 !important;
            }

            p {
                color: var(--white);
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                opacity: 0.9;
                margin-bottom: 0 !important;
            }

            .last__text {
                color: var(--white);
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 140%;
                margin-bottom: 0 !important;
            }
        }

        &.hero__img__section {
                position: relative;
                width: calc(50% - 20px);
    
                img {
                    max-width: calc(100% + 98px);
                    margin-left: -63px;
                    margin-top: -34px;
                }
        }
    }

}

@media only screen and (max-width:990px){
    .hero__section__main{
        flex-direction: column-reverse;
        padding-bottom: 100px;
        padding-top: 80px;
        .hero__sub__div{
            width: 100%;
        }
    }
}

@media only screen and (max-width:768px){
    .hero__section__main{
        .hero__sub__div{
            &.hero__img__section{
                width: 100%;
                img{
                    max-width: calc(90% + 98px);
                    margin-top: 0;
                    margin-left: -38px;
                }
            }
        }
    }
}