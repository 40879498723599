.features__section {

    .feature__item {
        display: flex;
        justify-content: space-between;
        padding: 80px 0;
        align-items: center;

        &.even__item {
            flex-direction: row-reverse;
        }

        .feature__item__img__section {
            position: relative;
            width: calc(50% - 20px);

            img {
                max-width: calc(100% + 98px);
                margin-left: -63px;
                margin-top: -34px;
            }
        }

        .feature__item__text__section {
            width: calc(50% - 20px);

            h3 {
                color: var(--Neutral-White, #F2F7FC);
                font-size: 44px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 0 !important;
            }

            p {
                margin-top: 16px;
                margin-bottom: 0 !important;
                color: var(--Neutral-White, #F2F7FC);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                padding-right: 50px;
            }
        }
    }
}

@media only screen and (max-width:768px){
    .features__section{
        .feature__item{
            padding: 50px 0;
            flex-direction: column;

            &.even__item{
                flex-direction: column;
            }
            .feature__item__text__section,.feature__item__img__section{
                width: 100%;
                img{
                    max-width: calc(90% + 98px);
                    margin-top: 0;
                    margin-left: -38px;
                }
            }
        }
    }
}