.eligibile__section__main {
    margin-top: 172px;
    padding-bottom: 172px;

    .eligible__description {
        color: var(--Neutral-White, #F2F7FC);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        max-width: 872px;
        margin: 16px auto;
        margin-bottom: 0 !important;
    }

    .cards__section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 47px;

        .eligible__card__main {
            width: calc(50% - 20px);
            padding: 25px 42px 25px 22px;
            background-color: var(--light-blue);

            p,
            h4 {
                margin-bottom: 0 !important;
            }

            .empty__line {
                width: 100%;
                height: 1px;
                opacity: 0.5;
                background: #F2F7FC;
                margin-top: 21px;
                margin-bottom: 27px;
            }

            .eligible__card__title {
                color: var(--Neutral-White, #F2F7FC);
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
                letter-spacing: 0.8px;
                text-transform: uppercase;

                span {
                    font-weight: 400;
                }
            }

            .eligible__point__section {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .eligble__point {
                    p {
                        color: var(--Neutral-White, #F2F7FC);
                        font-family: var(--worksans);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .eligibile__section__main {
        .cards__section {
            flex-direction: column;
            gap: 20px;

            .eligible__card__main {
                width: 100%;
            }
        }
    }
}