.primary__btn {
    padding: 14px 20px;
    border-radius: 5px;
    background: var(--primary-color);
    border: none;
    outline: none;
    color: var(--normal-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    span {
        color: var(--normal-blue);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        text-decoration: line-through;
    }
}