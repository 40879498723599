.navbar__container {
    z-index: 99999999;
    background-color: #0C2445;

    .navbar__main__container {

        .navbar__main {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .list__section {
                gap: 32px;

                button {
                    color: #F2F7FC;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    padding: 0;
                    border: none;
                    outline: none;
                    background-color: transparent;
                }
            }

            .menu__btn {
                padding: 0;
                background-color: transparent;
                border: none;
                outline: none;
                width: 50px;
            }

            .fixed__container {
                position: fixed;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 45px;
                height: 0;
            }

        }
    }
}

.mobile__menu__container {
    height: 0px;
    overflow: hidden;
    transition: height 0.3s ease;
    width: 100%;

    &.active__menu {
        height: 150px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 5px;
    }

    .mob__sub__div {
        border-radius: 5px;
        background-color: #fff;
        padding: 10px;

        .mobile__menu__section {
            .link_style {
                text-decoration: none;
                color: var(--dark-blue);
                font-size: 14px;
                font-weight: 500;
                line-height: 30px;
                transition: all 0.3s ease;
                border: none;
                outline: none;
                background-color: transparent;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}

.navbar__main__empty {
    width: 100%;
    height: 80px;
}


@media only screen and (max-width:768px) {
    .navbar__main {
        img {
            max-width: 220px;
        }
    }
}

// @media only screen and (max-width:525px){
//     .navbar__main{
//         img{
//             max-width: 170px;
//         }
//     }
// }