.what__you__learn__main {
    padding-top: 172px;
    display: flex;
    justify-content: space-between;

    .what__you__learn__subdiv {
        width: calc(50% - 20px);

        &.img__section {
            img {
                max-width: 100%;
            }
        }

        &.text__section {
            h3 {
                color: var(--Neutral-White, #F2F7FC);
                font-size: 44px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 0 !important;
            }

            .feature__list__item {
                display: flex;
                gap: 24px;
                margin-top: 32px;

                h4 {
                    color: var(--Neutral-White, #F2F7FC);
                    font-size: 22.5px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    margin-bottom: 0 !important;
                }

                p {
                    color: var(--Neutral-White, #F2F7FC);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 140%;
                    opacity: .9;
                    margin-top: 8px;
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}

.what__will__covered {
    margin-top: 172px;
    padding-bottom: 76px;

    h4 {
        color: var(--pink);
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        color: var(--Neutral-White, #F2F7FC);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        opacity: .9;
        margin: auto;
        margin-top: 16px;
        margin-bottom: 0 !important;
        max-width: 869.161px;
    }
}

@media only screen and (max-width:768px){
    .what__you__learn__main{
        padding-top: 120px;
        flex-direction: column;

        .what__you__learn__subdiv{
            width: 100%;

            &.text__section{
                margin-top: 64px;
            }
        }
    }
    
}