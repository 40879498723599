.videos__section__main {
    padding-top: 172px;

    h2 {
        color: var(--white);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        max-width: 869.161px;
        margin: 0 auto;
    }

    p {
        color: var(--white);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-top: 16px;
    }

    .videos__section{
        margin-top: 46px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .video{
            width: calc(33% - 23.33px);
            height: 290px;
            position: relative;
            background-color: #0C1b34;
            border-radius: 10px;
            
            .play__btn{
                background-color: transparent;
                padding: 0;
                border: none;
                outline: none;
                position: absolute;
                left: 26px;
                bottom: 18px;
            }
        }
    }
}

@media only screen and (max-width:990px){
    .videos__section__main{
        padding-top: 20px;
    }
}

@media only screen and (max-width:768px){
    .videos__section__main{       
        .videos__section{
            flex-direction: column;
            gap: 20px;
            .video{
                width: 100%;
            }
        }
    }
}

