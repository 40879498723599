.guaranty__section__main {
    border-radius: 16px;
    background: var(--pink);
    padding: 64px;
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .guaranty__text__section {
        h3 {
            color: var(--Neutral-White, #F2F7FC);
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 0 !important;
        }

        p {
            color: var(--Neutral-White, #F2F7FC);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            opacity: .9;
            margin-bottom: 0 !important;
            margin-top: 8px;
        }
    }

    .guaranty__btn__section {
        button {
            padding: 18px 26px;
            border-radius: 4px;
            background: var(--Accent-Accent-2, #3B3086);
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            border: none;
            outline: none;
            width: 211px;
        }
    }
}

@media only screen and (max-width:768px){
    .guaranty__section__main{
        padding: 32px;
        gap: 16px;
        flex-direction: column;
    }
}