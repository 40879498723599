.more__than__course__section {
    padding-top: 93px;

    p {
        color: var(--Neutral-White, #F2F7FC);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        opacity: .9;
        max-width: 872px;
        margin: 16px auto;
        margin-bottom: 0 !important;
    }

    .video__container{
        width: 100%;
        padding: 0 140px;
        margin-top: 41px;
        margin-bottom: 29px;
        img{
            width: 100%;
        }
    }
}

@media only screen and (max-width:768px){
    .more__than__course__section{
        .video__container{
            padding: 0;
        }
    }
}