@font-face {
    font-family: "Sora";
    src: url("Sora-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Sora";
    src: url("Sora-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Sora";
    src: url("Sora-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Sora";
    src: url("Sora-SemiBold.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Sora";
    src: url("Sora-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}