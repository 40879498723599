.footer__main {
    width: 100%;
    border-top: 1px solid #F2F7FC;
    padding: 45px 32px;

    .footer__sub__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        button {
            padding: 0;
            outline: none;
            border: none;
            background-color: transparent;
            padding: 12px;
            color: var(--Neutral-White, #F2F7FC);
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
        }

        p {
            color: var(--Neutral-White, #F2F7FC);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 0 !important;
        }
    }
}

@media only screen and (max-width:525px){
    .footer__main{
        padding: 45px 32px 104px;
    }
}