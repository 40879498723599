.pricing__main__section {
    padding-top: 172px;

    .section__description {
        color: var(--Neutral-White, #F2F7FC);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        opacity: .9;
        max-width: 872px;
        margin: 16px auto;
        margin-bottom: 0 !important;
    }

    .pricing__card__section {
        display: flex;
        justify-content: center;
        margin-top: 56px;

        .pricing__card {
            border-radius: 12px;
            border: 1px solid var(--Neutral-Black, #0D1C33);
            background: linear-gradient(0deg, #0E2D58 0%, #0E2D58 100%), #102542;
            padding: 32px;
            width: 100%;
            max-width: 620px;

            p {
                margin-bottom: 0 !important;
            }

            .card__title__section {
                p {
                    color: var(--Neutral-White, #F2F7FC);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 120%;
                    letter-spacing: 0.8px;
                    text-transform: uppercase;
                }
            }

            .card__price {
                color: var(--Neutral-White, #F2F7FC);
                font-size: 55px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-top: 12px;

                span {
                    text-decoration: line-through;
                }
            }

            .empty__line {
                width: 100%;
                height: 1px;
                opacity: 0.5;
                background: var(--white);
                margin: 32px 0;
            }

            .price__description {
                color: var(--Neutral-White, #F2F7FC);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            .featues__section {
                display: flex;
                flex-direction: column;
                gap: 32px;

                .feature__list__text__section {
                    p {
                        color: var(--Neutral-White, #F2F7FC);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }

                .get__started__btn {
                    border: none;
                    outline: none;
                    border-radius: 5px;
                    background: #FFC2C0;
                    padding: 14px 20px;
                    color: var(--Neutral-Black, #0D1C33);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;

                    span {
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}